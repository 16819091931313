$site-domain: "";
@import "variables";
@import "button";
@import "ui";
@import "fonts";
@import "aos/dist/aos.css";
@import "slick";


$img-path: "#{$site-domain}/Media/Image";
$background-color: #010407;
.spinner-h {
  position: fixed;
  background: #fbf9f7;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  transition: all 0.5s;
}

.box {
  position: relative;
  background: var(--tertiary-bg);
  color: var(--main-color);
  border: .1rem solid var(--main-border-color);
  margin: 0 0 3rem;

  .box-heading {
    padding: .5rem 2rem;
  }

  .box-content {
    padding: 2rem;

    > *:last-child {
      margin-bottom: 0;
    }
  }

  .box-footer {
    padding: .5rem 2rem;
  }

  &.box02 {
    border: 0;
    border-radius: 2rem;
    box-shadow: var(--main-box-shadow);
  }
}


.error__page {
  background: #F1F1F3;

  main {
    // min-height: calc(100vh - 8rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.error__page-content {
  padding: 0 0 23rem;

  & > img,
  & > svg {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 2rem;
  }

  h1 {
    font-size: 7rem;
    line-height: normal;
    font-weight: 500;
    margin-bottom: 3rem;
  }

  *:last-child {
    margin-bottom: 0;
  }
}

.page-notfound-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5rem;
  padding: 10rem 0;

  & > img,
  & > svg {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 2rem;
  }

  h1 {
    font-size: 7rem;
    line-height: normal;
    font-weight: 500;
    margin-bottom: 2rem;
  }

  *:last-child {
    margin-bottom: 0;
  }
}


.section01 {
  background: url(#{$img-path}/bg-section01.png) no-repeat 0 0 / 100% 100%;
  min-height: 80rem;
  padding: 40px 0;
  color: #fff;

  h1 {
    color: #fff;
  }

  .items {
    display: flex;
    position: relative;

    p {
      font-weight: 300;
      max-width: 380px;
    }

    .item {
      max-width: 50rem;

      .button {
        margin-top: 3rem;
      }

      &:last-of-type {
        position: absolute;
        max-width: none;
        right: -22rem;
      }
    }
  }

  @media only screen and (max-width: 1650px) {
    min-height: auto;
    .items {
      p {
        font-weight: 300;
        max-width: 380px;
      }

      .item {
        max-width: 50rem;

        &:last-of-type {
          position: static;
        }
      }
    }
  }
  @media only screen and (max-width: 1200px) {
    min-height: auto;
    background:   url(#{$img-path}/bg-section01.png) no-repeat 0 0 / 118% 100%;


    .items {
      flex-direction: column;
      align-items: center;

      .item {
        /* max-width: 100%;*/
        text-align: center;

        &:first-of-type {
          margin-bottom: 50px;
        }
      }
    }
  }
  @media only screen and (max-width: 480px) {
    background: url(#{$img-path}/bg-section01-sm.png) no-repeat 0 0 / 100% 100%;
    padding: 30px 0 50px;
    margin-bottom: 50px;
    .items {
      .item {
        .button {
          margin-top: 1rem;
        }
      }
    }
  }
}

.section02 {
  text-align: center;

  .button {
    position: relative;
    z-index: 99;
  }

  p {
    max-width: 770px;
    margin: 0 auto 5rem;
  }

  picture {
    display: flex;
    margin-top: -9rem;
    justify-content: center;
  }

  @media only screen and (max-width: 991px) {
    picture {
      margin-top: 2rem;
    }
  }
  @media only screen and (max-width: 480px) {
    margin-bottom: 1rem;
    picture {
      margin-top: -2rem;
      margin-bottom: 3rem;
    }
  }
}


.section03 {
  text-align: center;
  background: url("#{$img-path}/bg-section03.jpg") no-repeat 50% 50% / cover;
  color: #fff;
  padding: 5rem 0 17rem;

  p {
    max-width: 770px;
    margin: 0 auto 5rem;
  }

  h2, .h2 {
    color: #fff;
  }

  picture {
    display: flex;
    margin-top: -3rem;
    justify-content: center;
  }

  @media only screen and (max-width: 991px) {
    picture {
      margin-top: 2rem;
    }
  }
  @media only screen and (max-width: 480px) {
    margin-bottom: 5rem;
    padding-bottom: 25rem;
    padding-top: 1rem;
    picture {
      margin-top: -2rem;
      margin-bottom: 3rem;
    }
  }
}


.section08 {
  padding: 4rem 0 1rem;
  position: relative;
  z-index: 1;

  &.section08-02 {
    margin-top: -20rem;
    padding-top: 0;

    .items .item {
      padding: 50px 75px 25px 15px;
    }

    h3 {
      text-align: left;
      color: #fff;
    }

    &:after {
      display: none;
    }
  }

  h3 {
    text-align: center;
  }

  .main-holder {
    position: relative;
    z-index: 1;
  }

  &.justify-c {
    .items {
      justify-content: space-between;
    }
  }

  &.row-4item {
    margin-bottom: 7rem;

    .btn-hold {
      display: flex;
      justify-content: center;
    }

    .items {
      gap: 9rem 0;
      padding: 40px 0 35px;
    }
  }

  &:after {
    position: absolute;
    top: 0;
    right: 0;
    content: '';
    width: 100%;
    height: 100%;
    background: url("#{$img-path}/decor-section08.png") no-repeat 50% 50%;
  }

  .items {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    z-index: 1;
    gap: 9rem 14rem;
    padding: 60px 15px 100px;

    .item {
      width: 280px;
      padding: 25px 55px 25px 15px;
      border-radius: 20px;
      position: relative;
      font-size: 12px;
      font-weight: 300;
      border: 1px solid transparent;
      background: #fff;
      min-height: 188px;

      p {
        margin-bottom: 0;
      }

      &:after {
        content: "";
        position: absolute;
        right: 20px;
        top: -19px;
        width: 90px;
        height: 76px;
      }

      &:before {
        border-radius: 0 17px 0;
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: 0;
        height: 0;
        border-left: 200px solid transparent;
      }

      &.sup1 {
        &:after {
          background: url("#{$img-path}/sup1.svg") no-repeat 100% 100%;
        }
      }

      &.sup2 {
        &:after {
          background: url("#{$img-path}/sup2.svg") no-repeat 100% 100%;
        }
      }

      &.sup3 {
        &:after {
          background: url("#{$img-path}/sup3.svg") no-repeat 100% 100%;
        }
      }

      &.sup4 {
        &:after {
          background: url("#{$img-path}/sup4.svg") no-repeat 100% 100%;
        }
      }

      &.sup5 {
        &:after {
          background: url("#{$img-path}/sup5.svg") no-repeat 100% 100%;
        }
      }

      &.sup6 {
        &:after {
          background: url("#{$img-path}/sup6.svg") no-repeat 100% 100%;
        }
      }

      &.sup7 {
        &:after {
          background: url("#{$img-path}/sup7.svg") no-repeat 100% 100%;
        }
      }

      &.sup8 {
        &:after {
          background: url("#{$img-path}/sup8.svg") no-repeat 100% 100%;
        }
      }

      &.sup9 {
        &:after {
          background: url("#{$img-path}/sup9.svg") no-repeat 100% 100%;
        }
      }

      &.sup10 {
        &:after {
          background: url("#{$img-path}/sup10.svg") no-repeat 100% 100%;
        }
      }

      &.sup11 {
        &:after {
          background: url("#{$img-path}/sup11.svg") no-repeat 100% 100%;
        }
      }

      &.sup12 {
        &:after {
          background: url("#{$img-path}/sup12.svg") no-repeat 100% 100%;
        }
      }

      &.sup13 {
        &:after {
          background: url("#{$img-path}/sup13.svg") no-repeat 100% 100%;
        }
      }


      &.sup14 {
        &:after {
          background: url("#{$img-path}/sup14.svg") no-repeat 100% 100%;
        }
      }

      &.sup15 {
        &:after {
          background: url("#{$img-path}/sup15.svg") no-repeat 100% 100%;
        }
      }

      &.sup16 {
        &:after {
          background: url("#{$img-path}/sup16.svg") no-repeat 100% 100%;
        }
      }

      &.sup17 {
        &:after {
          background: url("#{$img-path}/sup17.svg") no-repeat 100% 100%;
        }
      }

      &.sup18 {
        &:after {
          background: url("#{$img-path}/sup18.svg") no-repeat 100% 100%;
        }
      }

      &.sup19 {
        &:after {
          background: url("#{$img-path}/sup19.svg") no-repeat 100% 100%;
        }
      }

      &.sup20 {
        &:after {
          background: url("#{$img-path}/sup20.svg") no-repeat 100% 100%;
        }
      }

      &.sup21 {
        &:after {
          background: url("#{$img-path}/sup21.svg") no-repeat 100% 100%;
          right: 12px;
          top: -26px;
          width: 97px;
          height: 93px;
        }
      }

      &.sup22 {
        &:after {
          background: url("#{$img-path}/sup22.svg") no-repeat 100% 100%;
        }
      }

      &.sup23 {
        &:after {
          background: url("#{$img-path}/sup23.svg") no-repeat 100% 100%;
        }
      }


      &.blue {
        border-color: #2C50DE;


        &:before {
          border-top: 140px solid #2C50DE;
        }
      }

      &.viol {
        border-color: #7189E9;


        &:before {
          border-top: 140px solid #7189E9;
        }
      }

      &.green {
        border-color: #2C9A64;

        &:before {
          border-top: 140px solid #2C9A64;
        }
      }

      &.blueL {
        border-color: #4996D2;

        &:before {
          border-top: 140px solid #4996D2;
        }
      }

      &.greenL {
        border-color: #79D1B9;

        &:before {
          border-top: 140px solid #79D1B9;
        }
      }

      &.black {
        border-color: #030203;

        &:before {
          border-top: 140px solid #030203;
        }
      }

      &.violD {
        border-color: #8E6A98;


        &:before {
          border-top: 140px solid #8E6A98;
        }
      }

      &.item-content {
        text-align: center;
        padding: 0;
        width: 300px;
        background: transparent;
      }

      &.red {
        border-color: #E43551;

        &:before {
          border-top: 140px solid #E43551;
        }
      }

      h3 {
        font-weight: 600;
        margin-top: -30px;
        margin-bottom: 5rem;
        min-height: 80px;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .items {
      gap: 9rem 3rem !important;
    }
    &.row-4item .items {
      justify-content: center;
    }
  }

  @media only screen and (max-width: 991px) {
    padding: 5rem 0 7rem;
    &.section08-10 {
      padding: 5rem 0 3rem;
    }
    .items {
      max-width: 670px;
      margin: 0 auto;
    }
    &.section08-02 {
      margin-top: -23rem;

      h3 {
        text-align: center;
      }
    }
  }

  @media only screen and (max-width: 700px) {
    &.section08-02 {
      margin-top: -18rem;

    }
    .items {
      max-width: 300px;
      padding: 30px 0 50px;
      gap: 5rem 3rem !important;
    }
  }

  @media only screen and (max-width: 480px) {
    &.section08-02 {
      margin-top: -18rem;

      h3 {
        font-size: 28px;
        margin-bottom: 1rem;
      }
    }
    &.row-4item {
      margin-bottom: 0;
    }
    .items {
      gap: 4rem 0 !important;
      padding: 58px 0 35px !important;

      .item {
        h3 {
          font-size: 24px;
          margin-top: 15px !important;
          margin-bottom: 1.5rem;
        }
      }
    }
    &:after {
      display: none;
    }
    h3 {
      text-align: center;
      font-weight: 600;
      font-size: 28px;
      display: block;
      line-height: 31px;
    }
  }
}


.section11 {
  position: relative;
  color: #fff;
  padding: 25px 0 10px;
  text-align: center;
  font-weight: 300;


  h2 {
    margin-bottom: 2rem;
  }

  &:after, &:before {
    position: absolute;
    content: '';
    background: conic-gradient(from -1deg at 100.78% 100%, #000 40.83203226327896deg, #000 51.72379910945892deg, #000 64.80000257492065deg, #000 106.19999527931213deg, #22B573 149.35232877731323deg, #000 272.4902057647705deg, #000 280.21350860595703deg, #216848 337.86068201065063deg);
    width: 51%;
    height: 325px;
    left: 0;
    top: 0;
  }

  &:after {
    transform: scaleX(-1);
  }

  &:before {
    background: conic-gradient(from -1deg at 100.78% 100%, #000 40.83203226327896deg, #000 51.72379910945892deg, #000 64.80000257492065deg, #000 106.19999527931213deg, #6630AE 149.35232877731323deg, #000 272.4902057647705deg, #000 280.21350860595703deg, #6630AE 337.86068201065063deg);
    right: 0;
    left: auto;
  }

  .main-holder {
    z-index: 1;
    position: relative;
    text-align: center;
  }

  h2 {
    color: #fff;
  }

  .items {
    display: flex;
    padding-top: 15px;
    justify-content: center;
    margin: 0 -15px;
    align-items: flex-start;

    .item {
      width: 305px;
      text-align: left;
      color: #FFF;
      font-size: 12px;
      font-weight: 500;
      padding: 3rem 15px;
      position: relative;

      &:after {
        content: "\e90d";
        font-family: 'icomoon';
        position: absolute;
        font-size: 11px;
        color: #79D1B9;
        right: 15px;
      }

      h4 {
        color: #79D1B9;
        font-size: 22px;
        font-weight: 600;
        line-height: 22px;
        margin-bottom: 2rem;
        position: relative;
        padding-left: 55px;

        &:after {
          position: absolute;
          top: -5px;
          left: 0;
          content: '';
          width: 40px;
          height: 40px;
          background: url("#{$img-path}/decor-icon01.png") no-repeat 50% 50%;
        }
      }

      &:nth-child(2) {
        width: 386px;
        padding-top: 0;

        &:after {
          display: none;
        }
      }

      &:last-of-type {
        &:after {
          color: #7088E8;
        }

        h4 {
          color: #7088E8;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 45px 0 10px;
    margin-bottom: 50px;
    &:after, &:before {
      background: conic-gradient(from 61deg at 100% 100%, #216848 -22.14deg, #000000 40.83deg, #000000 51.72deg, #000000 64.8deg, #000000 106.2deg, #22B573 149.35deg, #000000 272.49deg, #000000 280.21deg, #216848 337.86deg, #000000 400.83deg);
      height: 50%;
      width: 100%;
      transform: scaleX(1);
    }

    &:before {
      background: conic-gradient(from 307deg at 100% 100%, #6630AE -22.14deg, #000000 40.83deg, #000000 51.72deg, #000000 64.8deg, #000000 106.2deg, #6630AE 149.35deg, #000000 272.49deg, #000000 280.21deg, #6630AE 337.86deg, #000000 400.83deg);
      bottom: 0;
      top: auto;
      transform: scale(-1);
    }

    .items {
      flex-direction: column;
      align-items: center;

      .item {
        width: 100% !important;
        text-align: center;
        padding: 1rem 15px 5.5rem;

        p {
          margin-bottom: 3.5rem;
        }

        &:after {
          margin: 0 auto;
          left: 0;
        }

        h4 {
          display: inline-block;
        }
      }
    }
  }
}

.section12 {
  background: url("#{$img-path}/bg-section12.png") no-repeat 0 0;
  background-size: cover;
  min-height: 945px;
  display: flex;

  &.section12-02 {
    background: url("#{$img-path}/bg-section12-02.png") no-repeat 0 0;
    background-size: cover;
    min-height: 870px;

    .items .item {
      text-align: inherit;
      align-items: flex-start;

      .button {
        margin-top: 1rem;
      }
    }
  }

  h2 {
    color: #fff;
  }

  .main-holder {
    display: flex;
  }

  .items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .item {
      text-align: center;
      color: #FFF;
      font-size: 28px;
      font-weight: 300;
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        margin-bottom: 4.5rem;
      }

      .button {
        margin-top: 6.7rem;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    padding-bottom: 30px;
    .items {
      flex-direction: column-reverse;
      align-items: center;
      margin: 0 auto;
      justify-content: flex-end;

      .item {
        width: 100%;
        padding: 0;
      }
    }
  }
  @media only screen and (max-width: 480px) {
    background: url("#{$img-path}/bg-section12-sm.png") no-repeat 0 0;
    background-size: cover;
    min-height: 720px;
    padding-top: 50px;
    &.section12-02 {
      background: url("#{$img-path}/bg-section12-02-sm.png") no-repeat 0 0;
      background-size: cover;
      min-height: 705px;

      .items .item {
        text-align: center;
        align-items: center;

        .button {
          margin-top: 1.5rem;
        }
      }

    }
    .items {
      .item {
        font-size: 22px;

        &:last-of-type {
          margin-bottom: 30px;

          img {
            max-width: 290px;
          }
        }

        p {
          margin-bottom: 1.5rem;
        }

        img {
          max-width: 262px;
        }
      }
    }
  }
}


.section13 {
  background: url("#{$img-path}/bg-section13.png") no-repeat 50% 100%;
  background-size: cover;
  padding: 80px 0 23rem;
  color: #fff;
  text-align: center;
  font-weight: 300;

  &.section13-02 {
    background: url("#{$img-path}/bg-section13-02.png") no-repeat 50% 100%;
    background-size: cover;
    padding: 80px 0 11rem;
  }

  &.section13-03 {
    background: url("#{$img-path}/bg-section13-03.png") no-repeat 50% 100%;
    background-size: cover;
    padding: 80px 0 10rem;
  }

  &.section13-04 {
    background: url("#{$img-path}/bg-section13-04.png") no-repeat 50% 100%;
    background-size: cover;
    padding: 80px 0 10rem;
  }

  &.section13-05 {
    background: url("#{$img-path}/bg-section13-05.png") no-repeat 50% 100%;
    background-size: cover;
    padding: 80px 0 15rem;
  }

  &.section13-06 {
    background: url("#{$img-path}/bg-section13-06.png") no-repeat 50% 100%;
    background-size: cover;
    padding: 80px 0 25rem;
  }

  &.section13-07 {
    background: url("#{$img-path}/bg-section13-07.jpg") no-repeat 50% 100%;
    background-size: cover;
    padding: 80px 0 0;

    .button {
      margin-bottom: -3.5rem;
      min-width: 285px;
      min-height: 6rem;
      position: relative;
      z-index: 999;
    }
  }

  &.section13-08 {
    background: url("#{$img-path}/bg-section13-08.jpg") no-repeat 50% 100%;
    background-size: cover;
    padding: 80px 0 34rem;
  }


  &.section13-09 {
    background: url("#{$img-path}/bg-section13-09.png") no-repeat 50% 100%;
    background-size: cover;
    padding: 80px 0 46rem;
  }

  &.section13-10 {
    background: url("#{$img-path}/bg-section13-10.jpg") no-repeat 50% 100%;
    background-size: cover;
    padding: 80px 0 27rem;
  }

  h1 {
    color: #fff;
    max-width: 900px;
    margin: 0 auto 3rem;
  }

  p {
    margin-bottom: 5rem;
  }

  @media only screen and (max-width: 480px) {
    padding: 40px 0 7rem !important;
    &.section13-08 {
      padding: 40px 0 17rem !important;
    }
    &.section13-06 {
      padding: 40px 0 10rem !important;

      h1 {
        br {
          display: none;
        }
      }
    }
    &.section13-10 {
      padding: 40px 0 13rem !important;;
    }
    &.section13-07 {
      padding: 40px 0 0 !important;

      h1 {
        br {
          display: none;
        }
      }

      p {
        margin-bottom: 2.5rem;
      }

      .button {
        margin-bottom: -4rem;
        min-height: 5.5rem;
      }
    }
    &.section13-09 {
      padding: 40px 0 12rem !important;
    }

    h1 {
      margin: 0 auto 2rem;
    }
    p {
      margin-bottom: 3rem;
    }
  }
}

.section14 {
  padding: 45px 0 30px;

  .items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
  }

  @media only screen and (max-width: 768px) {
    padding: 50px 0 75px;
    .items {
      flex-direction: column;
      text-align: center;

      .item {
        width: 100%;
        padding: 0;
      }
    }
  }
}


.section15 {
  background: url("#{$img-path}/bg-section15.png") no-repeat 50% 0;
  background-size: cover;
  padding: 20rem 0 33rem;
  color: #fff;

  .main-holder {
    max-width: 1070px;
  }

  h2 {
    color: #fff;
    max-width: 900px;
    margin: 0 auto 3rem;
  }

  .item {
    max-width: 435px;
    margin-left: auto;
  }

  @media only screen and (min-width: 1921px) {
    padding: 20rem 0 55rem;
  }

  @media only screen and (max-width: 768px) {
    padding: 10rem 0 15rem;
    .item {
      max-width: 320px;
    }
  }

  @media only screen and (max-width: 480px) {
    padding: 5rem 0 20rem;
    .item {
      margin: 0 auto;
      text-align: center;
    }
    h2 {
      margin: 0 auto 2rem;
    }
  }
}

.section16, .section17, .section18, .section19 {
  margin-bottom: 5rem;

  .items {
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  @media only screen and (max-width: 768px) {
    padding: 0;
    .items {
      flex-direction: column;
      text-align: center;

      .item {
        width: 100%;
        padding: 0;

        &:first-of-type {
          max-width: 100%;
          margin-bottom: 3rem;
        }
      }
    }
  }
}


.section17, .section18, .section19 {
  padding: 7rem 0;
  margin-bottom: 0;

  .items {
    justify-content: space-between;
    max-width: 100%;


    .item {
      p {
        margin-bottom: 4rem;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 5rem 0 !important;
  }

  @media only screen and (max-width: 480px) {
    .items {
      .item {
        padding: 0;

        &:first-of-type {
          margin-bottom: 0;
        }

        p {
          margin-bottom: 3rem;
        }

        .button {
          margin-top: 3.5rem;
        }
      }
    }
  }
}

.section18 {
  background: #000;
  color: #fff;
  padding: 6rem 0;

  h2 {
    color: #fff;
  }

  @media only screen and (max-width: 768px) {
    .items {
      flex-direction: column-reverse;
    }
  }
}


.section19 {
  padding: 5rem 0 9rem;
}


.section20, .section27 {
  background: url("#{$img-path}/bg-section20.png") no-repeat 50% 0;
  background-size: cover;
  min-height: 642px;
  display: flex;

  h2 {
    color: #fff;
    margin-bottom: 3rem;
  }

  .main-holder {
    display: flex;
  }

  .items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -4rem;
    width: 100%;

    .item {
      color: #FFF;
      font-size: 28px;
      font-weight: 300;

      p {
        margin-bottom: 5rem;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    padding: 7rem 0;

    .items {
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
      justify-content: flex-start;

      .item {
        align-items: center;
        text-align: center;
        width: 100%;
        padding: 0;
      }
    }
  }
  @media only screen and (max-width: 480px) {
    background: url("#{$img-path}/bg-section20-sm.png") no-repeat 50% 50%;
    background-size: cover;
    padding: 5rem 0;
    min-height: 634px;
    h2 {
      margin-bottom: 2rem;
    }
    .items {
      .item {
        font-size: 14px;

        p {
          margin-bottom: 3rem;
        }

        img {
          margin-bottom: 3rem;
        }
      }
    }
  }
}

.section27 {
  background: url("#{$img-path}/bg-section27.png") no-repeat 50% 0;
  background-size: cover;

  &.section27-02 {
    background: url("#{$img-path}/bg-section27-02.png") no-repeat 50% 0;
    background-size: cover;

    .items {
      margin-top: 3rem;
    }
  }

  &.section27-03 {
    background: url("#{$img-path}/bg-section27-03.png") no-repeat 50% 0;
    background-size: cover;

    h2, .h2 {
      margin-bottom: 5rem;
    }

    .items {
      margin-top: 2rem;
    }
  }

  &.section27-04 {
    background: url("#{$img-path}/bg-section27-04.png") no-repeat 50% 0;
    background-size: cover;

    h2, .h2 {
      margin-bottom: 4rem;
    }

    .items {
      margin-top: 2rem;
    }
  }


  &.section27-05, &.section27-06 {
    background: url("#{$img-path}/bg-section27-05.jpg") no-repeat 50% 0;
    background-size: cover;
    min-height: 735px;

    h2, .h2 {
      margin-bottom: 4rem;
    }

    .items {
      margin-top: 2rem;
    }
  }

  &.section27-05 {
    .items {
      margin-top: 9rem;
    }
  }

  &.section27-06 {
    background: url("#{$img-path}/bg-section27-06.jpg") no-repeat 50% 0;
    background-size: cover;
    min-height: 638px;
  }

  .items {
    margin-top: -2rem;
    width: 100%;

    .item {
      p {
        margin-bottom: 5rem;
      }
    }
  }

  @media only screen and (min-width: 1921px) {
    &.section27-05 {
      min-height: 850px;
    }
  }
  @media only screen and (max-width: 991px) {
    padding: 13rem 0 0;
    .items {
      flex-direction: column-reverse;
      margin-left: 0;
      margin-right: 0;

      .item {
        width: 100%;
        max-width: 600px;
        margin: 0 auto 5rem;
        padding: 0;
      }
    }
    &.section27-05 {
      min-height: 500px;
      padding: 0;

      .items {
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
  @media only screen and (max-width: 480px) {
    background: url("#{$img-path}/bg-section27-sm.png") no-repeat 50% 0;
    background-size: cover;
    padding: 7rem 0 0;
    min-height: 634px;
    &.section27-02 {
      background: url("#{$img-path}/bg-section27-02-sm.png") no-repeat 50% 0;
      background-size: cover;
      padding: 5rem 0 0;
      min-height: 610px;

      .items {
        margin-top: 0;
        justify-content: flex-end;
        margin-left: 0;
        margin-right: 0;

        .item {
          margin: 0 auto 3rem;

          img {
            margin-bottom: 2.5rem;
          }
        }

      }
    }
    &.section27-03 {
      background: url("#{$img-path}/bg-section27-03-sm.png") no-repeat 50% 0;
      background-size: cover;
      padding: 5rem 0 0;
      min-height: 500px;

      h2 {
        margin-bottom: 3rem;
      }

      .items {
        margin-top: 0;
        justify-content: flex-end;
        margin-left: 0;
        margin-right: 0;
      }

      .items .item {
        margin-bottom: 0;
      }

      .items .item img {
        transform: scale(1);
        margin-bottom: 1.5rem;
      }
    }
    &.section27-04, &.section27-05, &.section27-06 {
      background: url("#{$img-path}/bg-section27-04-sm.png") no-repeat 50% 0;
      background-size: cover;
      padding: 5rem 0 0;
      min-height: 500px;

      h2 {
        margin-bottom: 2rem;
      }

      .items {
        margin-top: 0;
        justify-content: flex-end;
        margin-left: 0;
        margin-right: 0;
      }

      .items .item {
        margin-bottom: 0;
        font-size: 14px;

        p {
          margin-bottom: 3rem;
        }
      }

      .items .item img {
        transform: scale(1);
        margin-bottom: 1.5rem;
      }
    }
    &.section27-05, &.section27-06 {
      background: url("#{$img-path}/bg-section27-05-sm.jpg") no-repeat 50% 0;
      background-size: cover;
      min-height: 470px;

      h2 {
        margin-bottom: 3rem;
      }

      .items {
        justify-content: flex-start;
        margin-left: 0;
        margin-right: 0;
      }

      .items .item img {
        margin-bottom: 2rem;
      }
    }

    &.section27-06 {
      background: url("#{$img-path}/bg-section27-06-sm.jpg") no-repeat 50% 0;
      background-size: cover;
    }
    .items {
      .item {
        p {
          margin-bottom: 0;
        }

        img {
          margin-bottom: 4.5rem;

        }
      }
    }
  }
}


.section21 {
  padding: 10rem 0 0;
  text-align: center;

  h2 {
    margin-bottom: 2rem;
  }

  .items {
    align-items: flex-end;
    margin-left: 0;
    margin-right: 0;

    .item {
      margin-bottom: 10rem;

      p {
        margin-bottom: 0;
      }

      .button {
        margin-top: 5rem;
      }
    }
  }

  @media only screen and (max-width: 570px) {
    padding: 5rem 0 0;
    .items {
      flex-direction: column;
      align-items: center;
      margin-left: 0;
      margin-right: 0;

      .item {
        width: 100%;
        margin-bottom: 5rem;
        padding: 0;

        &:first-of-type {
          margin-bottom: 2rem;
        }

        .button {
          margin-top: 3rem;
        }
      }
    }
  }
}


.section24 {
  background: url("#{$img-path}/bg-section24.png") no-repeat 50% 100%;
  background-size: cover;
  padding: 6rem 0 25rem;
  color: #fff;

  .items {
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-left: 0;
    margin-right: 0;

    p {

      margin-bottom: 5rem;
    }
  }

  h2 {
    color: #fff;
    margin-bottom: 2rem;
  }

  &.section24-02 {
    padding: 1rem 0 25rem;

    .items {
      .img-h {
        padding: 0;
      }

      p {
        max-width: 465px;
      }
    }
  }

  &.section24-03 {
    padding: 7rem 0 22rem;
    background: url("#{$img-path}/bg-section24-03.png") no-repeat 50% 100%;
    background-size: cover;

    .items {

      .img-h {
        padding: 0;
        display: flex;
        justify-content: center;
      }

      .txt-h {
        margin-top: -9rem;
      }

      p {
        max-width: 490px;
      }
    }
  }


  &.section24-04 {
    padding: 5rem 0 15.5rem;
    background: url("#{$img-path}/bg-section24-04.png") no-repeat 50% 100%;
    background-size: cover;

    .items {
      .txt-h {
        h2 {
          margin-bottom: 3rem;
        }
      }

      p {

        margin-bottom: 6rem;
      }
    }
  }


  &.section24-05 {
    padding: 9rem 0 21.5rem;
    background: url("#{$img-path}/bg-section24-05.jpg") no-repeat 50% 100%;
    background-size: cover;

    .items {
      .img-h {
        padding: 0;
      }

      .txt-h {
        margin-top: -9rem;

      }

      .button {
        margin-top: 4rem;
      }

      p {
        margin-bottom: 1rem;
      }
    }
  }

  &.section24-06 {
    padding: 7rem 0 21.5rem;
    background: url("#{$img-path}/bg-section24-06.jpg") no-repeat 50% 100%;
    background-size: cover;

    .items {

      .txt-h {
        margin-top: -1rem;
      }

      .button {
        margin-top: 4rem;
      }

      p {
        max-width: 480px;
        margin-bottom: 1rem;
      }
    }
  }

  @media only screen and (max-width: 992px) {
    .items {
      .img-h {
        padding: 0;
      }
    }


    &.section24-03, &.section24-04, &.section24-05, &.section24-06 {
      .items {
        .img-h {
          padding: 0;
          display: flex;
          justify-content: center;
        }

        .txt-h {
          margin-top: 0;
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    padding: 5rem 0 7rem;

    &.section24-03 {
      padding: 7rem 0 7rem;
      background: url("#{$img-path}/bg-section24-03-sm.png") no-repeat 50% 0;
      background-size: cover;
    }
    &.section24-02 {
      padding: 5rem 0 6rem;
      margin-bottom: -25px;
    }
    .items {
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-left: 0;
      margin-right: 0;

      .txt-h {
        margin-top: 0;
        margin-bottom: 3rem;
      }

      p {
        margin: 0 auto 5rem;
      }

      .item {
        width: 100%;
        padding: 0;
      }
    }
  }

  @media only screen and (max-width: 480px) {
    &.section24-03 {
      padding: 5rem 0 3rem;

      .items .img-h {
        margin-bottom: 3rem;
      }
    }
    &.section24-04 {
      padding: 5rem 0;

      .items {
        .txt-h {
          h2 {
            margin-bottom: 2rem;
          }
        }

        p {
          max-width: 475px;
          margin-bottom: 5rem;
        }
      }
    }
    .items {
      .txt-h {
        margin-bottom: -2rem;
      }

      .img-h {
        margin-bottom: 5rem;
      }
    }
    &.section24-05 {
      padding: 5rem 0;

      .items {
        .txt-h {
          margin-bottom: 3rem;
        }

        .button {
          margin-top: 0;
        }

      }
    }
    &.section24-06 {
      padding: 5rem 0;

      .items {
        .txt-h {
          margin-bottom: 3rem;
        }

        .button {
          margin-top: 0;
        }

      }
    }
  }
}


.section25, .section26 {
  .items {
    align-items: center;
    justify-content: space-between;
    width: 100%;

    p {
      margin-bottom: 5rem;
    }
  }

  h2 {
    margin-bottom: 2rem;
  }

  @media only screen and (max-width: 768px) {
    padding: 5rem 0;
    .items {
      flex-direction: column-reverse;
      align-items: center;
      text-align: center;
      margin-left: 0;
      margin-right: 0;

      p {
        margin: 0 auto 3rem;
      }

      .img-h {
        margin-bottom: 3rem;
      }

      .txt-h {
        margin: 0 auto;
      }

      .item {
        width: 100%;
        padding: 0;
      }
    }
  }
}

.section26 {
  padding: 2rem 0 5.5rem;

  .items {
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .txt-h {
      max-width: 100%;
    }
  }


  @media only screen and (max-width: 768px) {
    padding: 5rem 0 5rem;
    .items {
      flex-direction: column;
      margin-left: 0;
      margin-right: 0;

      p {
        margin: 0 auto 3rem;
      }

      .img-h {
        margin-bottom: 3rem;
      }

      .item {
        width: 100%;
        padding: 0;
      }
    }
  }
}

.section28, .section29, .section30, .section32, .section33, .section36 {
  padding: 1rem 0;

  .items {
    align-items: center;
    justify-content: space-between;
    width: 100%;

    p {
      margin-bottom: 5rem;
    }
  }

  h2 {
    margin-bottom: 2rem;
  }

  @media only screen and (max-width: 991px) {
    padding: 5rem 0;
    .items {
      flex-direction: column-reverse;
      align-items: center;
      text-align: center;
      margin-left: 0;
      margin-right: 0;

      p {
        margin: 0 auto 3rem;
      }

      .img-h {
        margin-bottom: 5rem;
        margin-top: 2rem;
      }

      .txt-h {
        margin: 0 auto;
      }

      .item {
        width: 100%;
        padding: 0;
      }
    }
  }
}

.section29 {
  margin-bottom: 7rem;
  @media only screen and (max-width: 570px) {
    margin-bottom: 0;
  }
}

.section30 {
  padding: 5rem 0 8rem;
  @media only screen and (max-width: 570px) {
    padding: 5rem 0;
    .items {
      .img-h {
        margin-bottom: 0;
      }
    }
  }
}

.section32 {
  padding: 4rem 0;
  @media only screen and (max-width: 570px) {
    padding: 7rem 0 5rem;
    .items {
      margin-left: 0;
      margin-right: 0;

      .img-h {
        margin-bottom: 3rem;
      }

      p {
        margin: 0 auto 1rem;
      }

    }
  }
}

.section33 {
  padding: 7rem 0;
  @media only screen and (max-width: 570px) {
    padding: 5rem 0;
    .items {
      .img-h {
        margin-bottom: 3rem;
      }

      p {
        margin: 0 auto 2rem;
      }
    }
  }
}

.section34 {
  text-align: center;
  padding: 10rem 0 13rem;

  p {
    max-width: 500px;
    margin: 0 auto;
  }

  .items {
    display: flex;
    justify-content: space-between;
    margin: 3rem 0;
    padding: 0 3rem;
    width: 100%;

    .item {
      position: relative;
      flex: 1;

      &:after {
        width: 1px;
        height: 43px;
        background: #C7C7C7;
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      &:last-of-type {
        &:after {
          display: none;
        }
      }
    }
  }

  @media only screen and (max-width: 1100px) {
    .items {
      padding: 0;
    }
  }
  @media only screen and (max-width: 768px) {
    padding: 5rem 0 10rem;
    .items {
      flex-wrap: wrap;
      justify-content: center;
      margin: 3rem 0;

      .item {
        width: 50%;
        flex: auto;

        img {
          max-width: 136px;
        }

        &:after {
          width: 43px;
          height: 1px;
          left: 0;
          margin: 0 auto;
          top: auto;
          display: block;
        }
      }
    }
  }
}

.section35 {
  background: url("#{$img-path}/bg-section35.jpg") no-repeat 50% 100%;
  background-size: cover;
  min-height: 889px;

  .items {
    .txt-h {
      padding-top: 3rem;

      p {
        max-width: 500px;
        margin-bottom: 5rem;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    min-height: auto;
    .items {
      flex-direction: column;
      text-align: center;
      align-items: center;
      margin-left: 0;
      margin-right: 0;

      .item {
        width: 100%;
        padding: 0;
      }

      .txt-h {
        margin-bottom: 3rem;

        p {
          margin: 0 auto 3rem;
        }
      }

      .img-h {
        max-width: 350px;
        margin: 0 auto 23.5rem;
      }
    }
  }

  @media only screen and (max-width: 480px) {
    min-height: auto;
    background: url("#{$img-path}/bg-section35-sm.jpg") no-repeat 50% 100%;
    background-size: cover;
  }
}

.section36 {
  padding: 0 0 5.5rem;

  .items {
    .txt-h {
      max-width: 100%;

      p {
        margin: 0 auto 1rem;
      }

      .button {
        margin-top: 4rem;
      }
    }

  }

  @media only screen and (max-width: 991px) {
    .items .img-h {
      margin-top: 6rem;
      padding: 0;
    }
  }

  @media only screen and (max-width: 480px) {
    padding: 5rem 0;
    .items {
      .img-h {
        margin-bottom: 3rem;
        margin-top: 2rem;
      }
    }
  }
}


.section46 {
  position: relative;
  z-index: 1;

  h3 {
    text-align: center;
  }

  .main-holder {
    position: relative;
    z-index: 1;
  }

  &.justify-c {
    .items {
      justify-content: space-between;
      width: 100%;
    }
  }

  &:after {
    position: absolute;
    top: 0;
    right: 0;
    content: '';
    width: 100%;
    height: 100%;
    background: url("#{$img-path}/decor-section08.png") no-repeat 50% 40%;
  }

  .items {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    z-index: 1;
    padding: 20px 30px 25px;

    .item {
      padding: 25px 0 30px 15px;
      border-radius: 20px;
      position: relative;
      font-size: 12px;
      font-weight: 300;
      border: 1px solid transparent;
      background: #fff;
      width: calc(33.33% - 15px);
      margin-bottom: 30px;

      h5 {
        margin-bottom: 2rem;
      }

      &:before {
        border-radius: 0 17px 0;
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: 0;
        height: 0;
        border-left: 140px solid transparent;
      }

      ul {
        list-style-type: none;
        padding: 0 0 0 1.5rem;
        margin: 0;

        li {
          margin-bottom: 10px;


          &:last-of-type {
            margin-bottom: 0;
          }

          a {
            color: #000;
            font-size: 12px;
            font-weight: 300;
            display: block;
            position: relative;
            padding-right: 35px;

            &:before {
              content: "\33";
              position: absolute;
              right: 20px;
              top: -6px;
              font-family: icomoon;
              font-size: 24px;
            }

            &:hover {
              transition: all 0.3s ease;

              &:before {
                color: #838383;
              }
            }
          }
        }
      }

      &.blue {
        border-color: #2C50DE;

        &:before {
          border-top: 70px solid #2C50DE;
        }
      }

      &.violet {
        border-color: #8E6A98;


        &:before {
          border-top: 70px solid #8E6A98;
        }
      }

      &.green {
        border-color: #2C9A64;

        &:before {
          border-top: 70px solid #2C9A64;
        }
      }

      &.blue-light {
        border-color: #4996D2;

        &:before {
          border-top: 70px solid #4996D2;
        }
      }

      &.green-light {
        border-color: #79D1B9;

        &:before {
          border-top: 70px solid #79D1B9;
        }
      }

      &.black {
        border-color: #030203;

        &:before {
          border-top: 70px solid #030203;
        }
      }

      &.grey {
        border-color: #CECECE;

        &:before {
          border-top: 70px solid #CECECE;
        }
      }

      &.red {
        border-color: #E43551;

        &:before {
          border-top: 70px solid #E43551;
        }
      }

      h3 {
        font-weight: 600;
        margin-top: -30px;
        margin-bottom: 5rem;
        min-height: 80px;
      }
    }
  }


  @media only screen and (max-width: 991px) {
    .items {
      .item {
        width: calc(50% - 15px);
      }
    }
  }

  @media only screen and (max-width: 700px) {
    .items {
      .item {
        width: 100%;
        padding: 0;
      }
    }
  }

  @media only screen and (max-width: 480px) {
    .items {
      padding: 20px 0 25px;

      .item {
        padding: 25px 0 22px 15px;
        margin-bottom: 20px;

        &:before {
          border-left: 92px solid transparent;
        }
      }


      .item ul {
        padding: 0;

        li {
          margin-bottom: 12px;

          a {
            padding-right: 55px;

            &:before {
              right: 15px;
              top: -5px;
            }
          }
        }
      }
    }
    &:after {
      display: none;
    }
    h3 {
      text-align: center;
      font-weight: 600;
      font-size: 32px;
      display: block;
      min-height: 20px;
      margin-bottom: 1rem;
    }
  }
}


/* contactus */
.contactus__page main {
  padding-bottom: 0;
}

.contactus__details {
  padding: 36rem 0 10rem;
  background: url("#{$img-path}/bg-contact.png") no-repeat 50% 0;
  background-size: cover;
  color: #fff;
  min-height: 775px;
  font-size: 22px;
  font-weight: 300;

  .main-holder {
    max-width: 82rem;
  }

  @media only screen and (max-width: 768px) {
    /*  padding: 2rem 0 7rem;*/

  }
  @media only screen and (max-width: 480px) {
    padding: 26rem 0 0;
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    min-height: 550px;
    br {
      display: none;
    }
  }
}

.api__details {
  padding: 34rem 0 10rem;
  background: url("#{$img-path}/bg-api.png") no-repeat 50% 0;
  background-size: cover;
  color: #fff;
  min-height: 775px;
  font-size: 28px;
  font-weight: 300;
  margin-top: -34rem;

  h2 {
    color: #fff;
    margin-bottom: 3rem;
  }

  .button {
    margin-top: 3rem;
  }

  .main-holder {
    max-width: 92rem;
  }

  .contact__social_items {
    max-width: 435px;
    display: block;
  }

  @media only screen and (max-width: 480px) {
    padding: 20rem 0 0;
    font-size: 20px;
    font-weight: 300;
    text-align: center;
    min-height: 500px;
  }
}


.contact__social {
  &_items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 385px;
    margin-left: auto;

    .social_h {
      display: flex;
      gap: 36px;
      padding-top: 2rem;

      a {
        display: flex;
        width: 40px;
        height: 40px;
        color: #fff;
        font-size: 34px;
        align-items: center;
        justify-content: center;
        transition: all 0.8s ease;

        &:hover {
          text-decoration: none;
          color: var(--white-color);
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    &_items {
      margin: 0 auto;
    }
  }

  @media only screen and (max-width: 480px) {
    &_items {
      .social_h {
        gap: 25px;
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 0;

        a {
          width: 25px;
          height: 25px;
          color: #fff;
          font-size: 22px;

          &.icon-brand-yt {
            font-size: 22px;
          }
        }
      }
    }
  }
}


.contactus__banner {
  position: relative;
  padding: 20rem 0 21rem;
  background: linear-gradient(180deg, #E2E2E2 9.12%, #F4F4F4 36.27%, #FFF 76.98%);
  color: rgba(48, 48, 48, 0.75);
  text-align: center;
  font-size: 20px;
  line-height: 32px;
  z-index: 1;
  min-height: 927px;

  .sub-ttl {
    position: relative;
    top: -44px
  }

  .heading-ttl {
    position: relative;
    display: inline-block;
    padding: 0 16rem;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: -7rem;
      background: url(#{$img-path}/contactus-pin.png) 0 0 no-repeat;
      width: 128px;
      height: 144px;
      background-size: 100%;
    }

    .h1 {
      color: var(--white-color);
      text-align: center;
      text-shadow: 0px 4px 46.3px rgba(0, 0, 0, 0.10);
      font-size: 100px;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 0;
    }

    .h2 {
      color: #303030;
      font-size: 69px;
      line-height: 75px;
      position: relative;
      top: -44px
    }
  }

  .btn-hold {
    margin-top: -10px;
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: url(#{$img-path}/contactus-banner.png) 0 0 no-repeat;
    background-size: 100%;
    width: 1147px;
    height: 632px;
  }

  &:after {
    position: absolute;
    top: -1px;
    right: 0;
    content: '';
    width: 211px;
    height: 185px;
    background: url("#{$img-path}/decor-section__contact.png") no-repeat 0 0;
    background-size: 100%;
    z-index: 1;
  }

  .main-holder {
    text-align: center;
    position: relative;
  }

  @media only screen and (max-width: 991px) {
    padding: 25rem 0 21rem;
    .sub-ttl {
      position: relative;
      top: -44px
    }

    .heading-ttl {
      padding: 0;

      &:before {
        top: -17rem;
        width: 104px;
        height: 125px;
        margin: 0 auto;
        right: 0;
      }

      .h1 {
        font-size: 70px;
      }

      .h2 {
        font-size: 50px;
        line-height: 55px;
        top: -35px
      }
    }
  }

  @media only screen and (max-width: 480px) {
    padding: 24rem 0 6rem;
    min-height: auto;
    .heading-ttl:before {
      top: -15rem;
      width: 87px;
      height: 101px;
    }
    .heading-ttl .h1 {
      font-size: 49px;
    }
    .heading-ttl .h2 {
      font-size: 30px;
      line-height: 35px;
      top: -35px;
    }
    .btn-hold {
      margin-top: -20px;

      .button {
        min-width: 180px;
      }
    }

    &:before {
      width: 100%;
      height: 100%;
      background: url(#{$img-path}/contactus-banner-sm.png) 0 0 no-repeat;
      background-size: 100%;
    }

    &:after {
      width: 91px;
      height: 80px;
    }
  }
}

.contactUs-form .form .button {
  width: 100%;
}

.contactUs-form, .APIDocumentation-form, .joinUs-form, .registration-form {
  margin: 0 0 9rem;

  .form {
    max-width: 940px;
    margin: 0 auto;

    .form-group__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .form-group {
      width: calc(50% - 10px);

      &:nth-child(7), &:nth-child(8), &:nth-child(9), &:nth-child(10) {
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 570px) {
    margin: 0;
    .form {
      .btn-hold {
        .button {
          min-width: 180px;
        }
      }

      .form-group {
        width: 100%;

        &:last-of-type {
          width: 100%;
        }
      }
    }
  }
}

.APIDocumentation-form {
  margin: -32rem 0 0;
  position: relative;
  background: url(https://r0562.demenv.com/Media/Image/bg-section-api.png) no-repeat 0 50%;
  background-size: cover;
  padding: 32rem 0 38rem;

  .form input:-webkit-autofill, .form input:-webkit-autofill:hover, .form input:-webkit-autofill:focus {
    -webkit-text-fill-color: #fff !important;
  }

  .form-group .css-1jqq78o-placeholder, .form-group .css-1dimb5e-singleValue, .form-group .css-166bipr-Input, .form-group .css-qbdosj-Input, #react-select-2-input {
    color: #fff !important;
  }

  .react-tel-input .form-control:focus {
    background: #000 !important;
  }

  .form {
    max-width: 1008px;
    border-radius: 50px;
    background: rgba(255, 255, 255, 0.85);
    box-shadow: 0px 0px 18px 0px rgba(21, 37, 113, 0.50);
    padding: 55px 35px;

    .react-tel-input .selected-flag .arrow {
      border-top: 7px solid #fff;
    }

    .css-1fdsijx-ValueContainer {
      color: #fff;
    }

    .form-group .css-1jqq78o-placeholder, .form-group .css-1dimb5e-singleValue {
      color: #fff;
    }

    .css-tj5bde-Svg {
      fill: #fff;
      stroke: #fff;
    }

    .css-13cymwt-control, .css-t3ipsp-control {
      background: #000;
    }

    .react-tel-input .selected-flag .arrow.up {
      border-top: none;
      border-bottom: 7px solid #fff;
    }

    .form-control {
      background: #000;
      color: #fff;
    }

    .button {
      width: 100%;
    }

    ::-webkit-input-placeholder {
      color: #fff;

    }

    :-moz-placeholder, ::-moz-placeholder {
      color: #fff;
    }

    :-ms-input-placeholder, ::-ms-input-placeholder, input.placeholder, textarea.placeholder {
      color: #fff;

    }
  }

  @media only screen and (max-width: 1920px) {
    background-size: auto;
  }
  @media only screen and (max-width: 1220px) {
    margin: -27rem 0 0;
    padding: 30rem 0 31rem;
  }

  @media only screen and (max-width: 991px) {
    margin: -23rem 0 0;
    padding: 23rem 0 30rem;
    background-size: cover;
    .form {
      padding: 40px 8px;
      border-radius: 20px;
    }
  }

  @media only screen and (max-width: 570px) {
    margin: -20rem 0 0;
    padding: 10rem 0 25rem;
  }
  @media only screen and (max-width: 480px) {
    margin: -10rem 0 0;
    padding: 7rem 0 35rem;
  }
  @media only screen and (max-width: 360px) {
    margin: -6rem 0 0;
    padding: 4.5rem 0 34rem;
    .re-captcha-h {
      transform: scale(0.9);
    }
  }
}


.joinUs-banner {
  background: url("#{$img-path}/bg-joiunUs.png") no-repeat 50% 100%;
  background-size: cover;
  padding: 80px 0 19.5rem;
  color: #fff;
  font-weight: 300;

  h1 {
    color: #fff;
  }

  .items {
    display: flex;

    .item {
      text-align: center;
      max-width: 400px;

      img {
        margin-top: -2rem;
      }
    }
  }

  @media only screen and (max-width: 570px) {
    padding: 40px 0 21.5rem;
    .items {
      flex-direction: column-reverse;
      align-items: center;
      margin-left: 0;
      margin-right: 0;

      .item {
        img {
          margin-top: 10px;
        }
      }
    }
  }
}


.joinUs-form {
  position: relative;
  padding: 3rem 0 15.5rem;
  margin: -21rem 0 0;

  &:after {
    background: url("#{$img-path}/bg-joiunUs-01.png") no-repeat 0 0;
    background-size: cover;
    position: absolute;
    content: '';
    width: 100%;
    height: 300px;
    bottom: 0;
  }

  .items {
    display: flex;

    .item {
      img {
        position: relative;
        top: 6rem;
      }

      &:first-of-type {
        margin-top: auto;

        img {
          top: -2rem;
        }
      }
    }
  }

  .form {
    max-width: 1008px;
    border-radius: 50px;
    background: linear-gradient(225deg, hsl(351deg 82% 56% / 90%) 23.38%, hsl(206deg 77% 43% / 90%) 81.73%);
    padding: 35px;
    margin-top: 2rem;

    .form-group {
      width: calc(50% - 10px);

      &:nth-child(7), &:nth-child(8), &:nth-child(9), &:nth-child(10) {
        width: calc(50% - 10px);
      }

      &:nth-child(11) {
        width: 100%;
      }
    }

    .form-control {
      background: #F9F3F2;
    }

    .button {
      width: 100%;
      background: linear-gradient(270deg, #000 0%, #000 100%);
      border: 0;

      &:hover,
      &.active {
        background: linear-gradient(270deg, #000 0%, #E43551 100%);
        border-color: transparent;
      }
    }

    .text-error {
      color: #fff;
    }
  }

  @media only screen and (max-width: 1200px) {
    .form {
      max-width: 500px;
      border-radius: 20px;
      margin-top: 2rem;

      .form-group {
        width: 100% !important;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 3rem 0 2.5rem;
    .items {
      flex-direction: column;
      margin-left: 0;
      margin-right: 0;

      .item {
        text-align: center;

        img {
          position: relative;
          z-index: 1;
          top: 0;
          margin: 0 auto;
        }
      }
    }
  }

  @media only screen and (max-width: 570px) {
    margin: -18rem 0 0;
    .form {
      padding: 40px 8px;
      margin: 0 15px;
    }
  }

  @media only screen and (max-width: 360px) {
    .re-captcha-h {
      transform: scale(0.9);
    }
  }
}


.registration-banner {
  background: url("#{$img-path}/bg-registration-form.jpg") no-repeat 50% 0;
  background-size: cover;
  padding: 14rem 0 11.5rem;
  margin-bottom: 10rem;

  h1 {
    text-align: center;
    color: #fff;
  }

  @media only screen and (max-width: 480px) {
    padding: 11rem 0 8.5rem;
    margin-bottom: 5rem;
  }
}

.registration-form {
  .form {
    .form-group {
      width: calc(50% - 10px);

      &:nth-child(7) {
        width: calc(50% - 10px);
      }

      &:nth-child(5) {
        width: 100%;
      }
    }
  }

  .re-captcha-parent {
    margin-bottom: 70px;
  }

  .form-group-check {
    width: 490px !important;
    margin: 0 auto;
    position: absolute;
    bottom: 6rem;
    right: 0;
    left: 0;

    .form-check {
      width: 490px;
      margin: 0 auto 18px;
    }
  }

  @media only screen and (max-width: 570px) {
    margin-bottom: 5rem;
    .form {
      .form-group {
        width: 100% !important;
      }
    }
    .form-group-check .form-check {
      width: 100%;
    }
    .re-captcha-parent {
      margin-bottom: 90px;
    }
  }
}

.hireUs-form {
  margin: 1rem 0 9rem;
  position: relative;
  z-index: 9;

  .main-holder {
    position: relative;

    &:before {
      position: absolute;
      top: -38rem;
      right: -25rem;
      content: '';
      width: 702px;
      height: 627px;
      background: url("#{$img-path}/decor-section__hireUs.png") no-repeat 0 0;
      background-size: 100%;
      z-index: 1;
    }
  }

  .form {
    max-width: 770px;
    margin: 0 auto;

    .form-group__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .form-group {
      width: calc(50% - 10px);

      &:nth-child(7) {
        width: 100%;
      }

      &:last-of-type, &:nth-last-child(-n+2) {
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 480px) {
    margin: 0 0 10rem;

    .main-holder:before {
      top: -14rem;
      right: -39rem;
    }

    .form {

      .btn-hold {
        .button {
          min-width: 180px;
        }
      }

      .form-group {
        width: 100%;

        &:last-of-type {
          width: 100%;
        }
      }
    }
  }
}


.rtl-page .contactus-banner:before {
  transform: scaleX(-1);
}

@media only screen and (max-width: 1200px) {
  .page-notfound-content {
    flex-direction: column;
    gap: 3rem;
  }

  .contactus-details {
    .items {
      .item {
        flex-direction: column;
        align-items: center;

        .text {
          text-align: center;
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
}

@media only screen and (max-width: 768px) {

  .error__page-content {
    padding: 0 0 30rem;

    h1 {
      font-size: 3rem;
      margin-bottom: 2.5rem;
    }
  }

  .page-notfound-content {
    padding: 16rem 0;

    h1 {
      font-size: 3rem;
    }
  }

  .contactus-details {
    padding: 1rem 0 4rem;

    .items {
      flex-direction: column;

      .item {
        flex-direction: row;
        padding: 2rem;
        align-items: flex-start;

        .text {
          text-align: start;
        }
      }
    }
  }

  .contactus-banner {
    &:before {
      background: #111D25 url(#{$img-path}/bg-contactus-banner-sm.jpg) 50% 50% / cover;
    }

    .main-holder {
      min-height: 47.6rem;
    }
  }
}

.tradingview-widget-container {
  background: var(--fifth-bg);
}

[class*=content-section] {
  position: relative;
  padding: 10rem 0;
}


.payment {
  background: #f6f6f6;
  padding: 24rem 0 14rem;
  position: relative;

  &:after {
    position: absolute;
    top: -1px;
    left: 0;
    content: '';
    width: 284px;
    height: 224px;
    background: url("#{$img-path}/decor-section10-1.png") no-repeat 0 0;
    background-size: 100%;
  }

  &:before {
    position: absolute;
    bottom: 0;
    right: 0;
    content: '';
    width: 211px;
    height: 185px;
    background: url("#{$img-path}/decor-section10-2.png") no-repeat 0 0;
    background-size: 100%;
  }

  .items {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .txt-h {
      width: 370px;
      color: #303030;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      position: relative;
      z-index: 1;

      .num {
        position: relative;
        max-width: 205px;
        margin-bottom: 90px;

        sup {
          position: relative;
          top: -32px;
        }

        &:before {
          position: absolute;
          top: -8rem;
          right: 0;
          content: '';
          width: 126px;
          height: 133px;
          background: url("#{$img-path}/decor-section10-3.png") no-repeat 0 0;
          background-size: 100%;
        }

        &:first-of-type {
          padding-bottom: 25px;
          margin-bottom: 110px;

          &:after {
            background: #2a4ce9;
            width: 165px;
            bottom: 0;
            height: 1px;
            position: absolute;
            left: 0;
            content: '';
          }
        }

        p {
          color: #289D3B;
          font-size: 75px;
          font-style: normal;
          font-weight: 700;
          line-height: 52px;
        }
      }
    }

    .img-h {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  @media only screen and (max-width: 1600px) {
    padding: 10rem 0;
    .img-h {
      img {
        max-width: 67%;
      }
    }
    .items {
      .txt-h {
        .num {
          max-width: 165px;
          margin-bottom: 50px;

          &:before {
            top: -6rem;
            width: 95px;
            height: 100px;
          }

          &:first-of-type {
            padding-bottom: 18px;
            margin-bottom: 85px;
          }

          p {
            font-size: 60px;
            line-height: 38px;

            sup {
              top: -40px;
            }
          }
        }
      }
    }

  }

  @media only screen and (max-width: 992px) {
    padding-top: 0;

    &:after {
      width: 91px;
      height: 80px;
    }
    &:before {
      display: none;
    }
    .items {
      justify-content: center;
      flex-direction: column;
      margin: 0 -1.5rem;

      .img-h {
        position: static;
        width: 100%;

        img {
          max-width: 100%;
          width: 100%;
        }
      }

      .txt-h {
        width: 100%;
        font-size: 18px;
        line-height: 28px;
        padding: 10rem 2rem 0;

        .num {
          margin: 0 auto 50px;
        }
      }
    }
  }

  @media only screen and (max-width: 480px) {
    background: linear-gradient(0deg, rgba(245, 245, 245, 0.00) 41.54%, #F5F5F5 49.22%);
  }
}


.newsletter-form {
  background: linear-gradient(270deg, #A0C9C9 15.72%, #48D0D0 78.47%);
  height: 611px;
  position: relative;
  color: var(--white-color);
  border-radius: 315px 0 0 0;

  &:before {
    position: absolute;
    top: 0;
    right: 0;
    content: '';
    width: 211px;
    height: 185px;
    background: url("#{$img-path}/decor-newsletter-03.png") no-repeat 0 0;
    background-size: 100%;
    z-index: 1;
  }

  &:after {
    position: absolute;
    bottom: -185px;
    left: 0;
    content: '';
    width: 211px;
    height: 185px;
    background: url("#{$img-path}/decor-newsletter-04.png") no-repeat 0 0;
    background-size: 100%;
    z-index: 1;
  }

  .form-check {
    padding-left: 40px;
  }


  ::-webkit-input-placeholder {
    color: var(--white-color) !important;
  }

  h2 {
    color: var(--white-color);
    margin-bottom: 4rem;
    z-index: 999;
    position: relative;
  }

  .main-holder {
    height: 100%;
  }

  .items {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    text-align: center;
  }


  .img-h {
    position: absolute;
    top: -62px;
    left: 87px;
  }

  .form {
    display: flex;
    gap: 15px;
    max-width: 570px;
    width: 100%;

    &:before {
      position: absolute;
      bottom: -11rem;
      right: -32rem;
      content: '';
      width: 893px;
      height: 611px;
      background: url("#{$img-path}/decor-newsletter-02.png") no-repeat 0 0;
      background-size: 100%;
      z-index: -1;
    }

    &:after {
      position: absolute;
      bottom: -109px;
      left: -60px;
      content: '';
      width: 517px;
      height: 334px;
      background: url("#{$img-path}/decor-newsletter-01.png") no-repeat 0 0;
      background-size: 100%;
      z-index: -1;
    }


    .button {
      background: var(--white-color);
      color: #000;

      &:hover {
        background: #000;
        color: var(--white-color);
        border-color: #000;
      }
    }
  }

  .form-group__list {
    flex: 1;
  }

  .form .form-control {
    border-color: var(--white-color);
    color: var(--white-color);
  }

  .form-group label, .form-group .form-label {
    color: var(--white-color);
  }

  .form-group label:after, .form-group .form-label:after {
    border-color: var(--white-color);
  }

  .form-group label:before, .form-group .form-label:before {
    background: var(--white-color);
  }

  .re-captcha-h {
    display: block;
  }

  @media only screen and (max-width: 1700px) {
    .form {
      flex-direction: column;
      max-width: 400px;
      margin: 0 auto;

      &:before {
        display: none;
      }

      &:after {
        bottom: -77px;
        left: -100px;
        width: 447px;
        height: 291px;
      }
    }
  }

  @media only screen and (max-width: 1500px) {
    .img-h {
      left: 136px;
      top: auto;
      bottom: 0;
      max-width: 520px;
    }
  }
  @media only screen and (max-width: 1100px) {
    .img-h {
      max-width: 400px;
    }
  }
  @media only screen and (max-width: 991px) {
    height: auto;
    padding: 60px 15px 75px;
    .form:after {
      bottom: -64px;
    }
    .items {
      justify-content: center;
    }
    .img-h {
      position: static;
      margin-bottom: 3rem;
    }
  }
  @media only screen and (max-width: 768px) {
    border-radius: 90px 0 0 0;
    &:before, &:after {
      width: 91px;
      height: 80px;
    }
    &:after {
      bottom: -80px;
    }
    .form .btn-hold {
      margin-top: -5px;

      .button {
        min-width: 180px;
      }
    }
  }
  @media only screen and (max-width: 480px) {
    h2 {
      padding: 0 5px;
    }
    .form:after {
      bottom: -76px;
      left: auto;
      right: -55px;
      width: 320px;
      height: 379px;
      background: url(#{$img-path}/decor-newsletter-01-sm.png) no-repeat 0 0;
    }
  }
}


.questions {
  &__holder {
    background-color: $background-color;
    background-image: url("#{$img-path}/questions-img.png"),
    url("#{$img-path}/questions-decor-2.png");
    background-position: center bottom, 85% 80%;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 20px;
    font-weight: 300;
    overflow: hidden;
    line-height: 33px;

    .txt-h {
      max-width: 640px;
      margin: 0 auto;
      position: relative;
      z-index: 9;
    }

    .main-holder {
      position: relative;
      min-height: 925px;
      padding: 16rem 1.5rem;
      text-align: center;

      &:before {
        position: absolute;
        bottom: -10%;
        left: -40%;
        content: '';
        width: 908px;
        height: 945px;
        background: url("#{$img-path}/questions-decor-3.png") no-repeat 0 0;
        background-size: 100%;
      }

      &:after {
        content: "";
        position: absolute;
        width: 788px;
        height: 339px;
        top: -45px;
        right: -259px;
        background: url("#{$img-path}/questions-decor.png") no-repeat 0 0;
        background-size: 100%;
      }
    }

    h2 {
      background: var(--Help, linear-gradient(91deg, #46CD98 35.11%, #159E84 44.04%, #2D6776 55.63%));
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 69px;
      font-style: normal;
      font-weight: 400;
      line-height: 75px;
      text-transform: capitalize;
    }

    .form {
      display: flex;
      border-radius: 11px;
      background: var(--Help-button, linear-gradient(90deg, #389C75 88.49%, #3B747C 98.19%));
      margin: 40px 0 35px;

      .form-group__list {
        flex: 1;

        .form-group {
          margin: 0;
        }
      }

      .button {
        background: transparent;
        min-width: 85px;
        border: none;
        font-size: 0;
        position: relative;
        min-height: 38px;

        &:after {
          content: "\e91a";
          font-family: 'icomoon';
          position: absolute;
          font-size: 1.7rem;
          color: #fff;
          display: flex;
        }
      }

      .form-control {
        padding: 1.5rem 4rem;
        background: #fff;
        border: 1px solid #fff;
        font-weight: 500;
        border-radius: 10px;

        &:focus {
          border-color: #fff;
          background: #fff;
        }
      }

      .form-label {
        display: none;
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 60px;
    z-index: 1;
    position: relative;
  }

  &__item {
    border-radius: 20px;
    border: 1px solid #3B747C;
    color: #4ABC91;
    font-size: 18px;
    line-height: 32px;
    background: #010407;
    width: calc(33.33% - 15px);
    transition: all 0.3s ease;
    text-align: initial;
    padding: 4px 21px;
    display: flex;
    align-items: center;
    margin-bottom: 17px;
    height: 40px;

    &:hover {
      background: #2a4ce9;
      color: #000;
      text-decoration: none;
    }
  }


  @media only screen and (max-width: 991px) {
    &__item {
      width: calc(50% - 15px);
    }
  }
  @media only screen and (max-width: 768px) {
    &__holder {
      .main-holder {
        min-height: 800px;
        padding: 6rem 1.5rem;
      }

      h2 {
        font-size: 32px;
        line-height: 42px;
        margin-bottom: 27px;
      }

      .form .button {
        min-width: 60px;
      }

      .form .form-control {
        padding: 1.5rem 1rem;
      }
    }
    &__list {
      margin-top: 80px;
    }
    &__item {
      width: 100%;
      font-size: 14px;
      line-height: 16px;
      padding: 4px 17px;
      margin-bottom: 17px;
    }
  }
  @media only screen and (max-width: 480px) {
    &__holder {
      background-image: url("#{$img-path}/questions-img-sm.png"),
      url("#{$img-path}/questions-decor-2-sm.png");
      background-position: center 80%, 100% 42%;

      .main-holder {

        &:before {
          bottom: 25%;
          left: -42%;
          width: 377px;
          height: 376px;
        }
      }
    }
  }
}

.help-center-list__component {
  padding: 13rem 0 15rem;
  position: relative;
  font-size: 20px;

  .news-list {
    margin-bottom: 0;
  }

  &:before {
    position: absolute;
    top: -1px;
    right: 0;
    content: '';
    width: 211px;
    height: 185px;
    background: url("#{$img-path}/help-center-decor-2.png") no-repeat 0 0;
    background-size: 100%;
    z-index: 1;
  }

  &:after {
    position: absolute;
    bottom: -1px;
    right: 0;
    content: '';
    width: 211px;
    height: 185px;
    background: url("#{$img-path}/help-center-decor-1.png") no-repeat 0 0;
    background-size: 100%;
    z-index: 1;
  }

  .news-categories {
    margin-top: 6rem;
  }

  @media only screen and (max-width: 768px) {
    padding: 6rem 0 8rem;
    p {
      padding: 0 1.5rem;
      line-height: 32px;
    }
    &:before {
      width: 91px;
      height: 85px;
    }
    &:after {
      width: 91px;
      height: 80px;
    }
    .news-categories {
      margin-top: 4rem;
    }
  }
}


/*bootstrap fixes*/
@media only screen and (min-width: 575px) {
  .text-xs-start {
    text-align: start !important;
  }

  .text-xs-end {
    text-align: end !important;
  }
}

@media only screen and (min-width: 576px) {
  .text-sm-start {
    text-align: start !important;
  }

  .text-sm-end {
    text-align: end !important;
  }
}

@media only screen and (min-width: 768px) {
  .text-md-start {
    text-align: start !important;
  }

  .text-md-end {
    text-align: end !important;
  }
}

@media only screen and (min-width: 992px) {
  .text-lg-start {
    text-align: start !important;
  }

  .text-lg-end {
    text-align: end !important;
  }
}

@media only screen and (min-width: 1200px) {
  .text-xl-start {
    text-align: start !important;
  }

  .text-xl-end {
    text-align: end !important;
  }
}

/* offsets */
.rtl-page *[class*=offset-] {
  margin-left: 0 !important;
}

@media (min-width: 576px) {
  .rtl-page .offset-sm-1 {
    margin-right: 8.333333%
  }

  .rtl-page .offset-sm-2 {
    margin-right: 16.666667%
  }

  .rtl-page .offset-sm-3 {
    margin-right: 25%
  }

  .rtl-page .offset-sm-4 {
    margin-right: 33.333333%
  }

  .rtl-page .offset-sm-5 {
    margin-right: 41.666667%
  }

  .rtl-page .offset-sm-6 {
    margin-right: 50%
  }

  .rtl-page .offset-sm-7 {
    margin-right: 58.333333%
  }

  .rtl-page .offset-sm-8 {
    margin-right: 66.666667%
  }

  .rtl-page .offset-sm-9 {
    margin-right: 75%
  }

  .rtl-page .offset-sm-10 {
    margin-right: 83.333333%
  }

  .rtl-page .offset-sm-11 {
    margin-right: 91.666667%
  }
}

@media (min-width: 768px) {
  .rtl-page .offset-md-1 {
    margin-right: 8.333333%
  }

  .rtl-page .offset-md-2 {
    margin-right: 16.666667%
  }

  .rtl-page .offset-md-3 {
    margin-right: 25%
  }

  .rtl-page .offset-md-4 {
    margin-right: 33.333333%
  }

  .rtl-page .offset-md-5 {
    margin-right: 41.666667%
  }

  .rtl-page .offset-md-6 {
    margin-right: 50%
  }

  .rtl-page .offset-md-7 {
    margin-right: 58.333333%
  }

  .rtl-page .offset-md-8 {
    margin-right: 66.666667%
  }

  .rtl-page .offset-md-9 {
    margin-right: 75%
  }

  .rtl-page .offset-md-10 {
    margin-right: 83.333333%
  }

  .rtl-page .offset-md-11 {
    margin-right: 91.666667%
  }
}

@media (min-width: 992px) {
  .rtl-page .offset-lg-1 {
    margin-right: 8.333333%
  }

  .rtl-page .offset-lg-2 {
    margin-right: 16.666667%
  }

  .rtl-page .offset-lg-3 {
    margin-right: 25%
  }

  .rtl-page .offset-lg-4 {
    margin-right: 33.333333%
  }

  .rtl-page .offset-lg-5 {
    margin-right: 41.666667%
  }

  .rtl-page .offset-lg-6 {
    margin-right: 50%
  }

  .rtl-page .offset-lg-7 {
    margin-right: 58.333333%
  }

  .rtl-page .offset-lg-8 {
    margin-right: 66.666667%
  }

  .rtl-page .offset-lg-9 {
    margin-right: 75%
  }

  .rtl-page .offset-lg-10 {
    margin-right: 83.333333%
  }

  .rtl-page .offset-lg-11 {
    margin-right: 91.666667%
  }
}

@media (min-width: 1200px) {
  .rtl-page .offset-xl-1 {
    margin-right: 8.333333%
  }

  .rtl-page .offset-xl-2 {
    margin-right: 16.666667%
  }

  .rtl-page .offset-xl-3 {
    margin-right: 25%
  }

  .rtl-page .offset-xl-4 {
    margin-right: 33.333333%
  }

  .rtl-page .offset-xl-5 {
    margin-right: 41.666667%
  }

  .rtl-page .offset-xl-6 {
    margin-right: 50%
  }

  .rtl-page .offset-xl-7 {
    margin-right: 58.333333%
  }

  .rtl-page .offset-xl-8 {
    margin-right: 66.666667%
  }

  .rtl-page .offset-xl-9 {
    margin-right: 75%
  }

  .rtl-page .offset-xl-10 {
    margin-right: 83.333333%
  }

  .rtl-page .offset-xl-11 {
    margin-right: 91.666667%
  }
}

@media (min-width: 1400px) {
  .rtl-page .offset-xxl-1 {
    margin-right: 8.333333%
  }

  .rtl-page .offset-xxl-2 {
    margin-right: 16.666667%
  }

  .rtl-page .offset-xxl-3 {
    margin-right: 25%
  }

  .rtl-page .offset-xxl-4 {
    margin-right: 33.333333%
  }

  .rtl-page .offset-xxl-5 {
    margin-right: 41.666667%
  }

  .rtl-page .offset-xxl-6 {
    margin-right: 50%
  }

  .rtl-page .offset-xxl-7 {
    margin-right: 58.333333%
  }

  .rtl-page .offset-xxl-8 {
    margin-right: 66.666667%
  }

  .rtl-page .offset-xxl-9 {
    margin-right: 75%
  }

  .rtl-page .offset-xxl-10 {
    margin-right: 83.333333%
  }

  .rtl-page .offset-xxl-11 {
    margin-right: 91.666667%
  }
}

.cookies-h {
  border-radius: 20px 20px 0px 0px;
  background: var(--white-color);
  padding: 20px 25px 28px;
  position: fixed;
  z-index: 9999;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);

  .social {
    gap: 3rem;

    a {
      width: 2rem;
      height: 3rem;
      font-size: 2rem;
      line-height: 2rem;
      color: #303030;

      .icon-brand-in {
        font-size: 21px;
        line-height: 20px;
      }

      .icon-brand-yt {
        font-size: 26px;
        line-height: 26px;
      }

      .icon-brand-wa {
        font-size: 22px;
        line-height: 22px;
      }
    }
  }

  .items {
    display: flex;
    justify-content: space-between;
    align-items: self-end;
    margin: 0 -15px;
    width: 100%;
    color: #1a1a1a;

    .logo-h {
      margin-bottom: 30px;
      display: block;

      img {
        max-width: 250px;
      }
    }

    .item {
      padding: 0 15px;

      &:nth-child(2) {
        flex: 1;
        text-align: center;
        font-size: 14px;
        line-height: 20.343px;

        p {
          margin-bottom: 0;

          a {
            text-decoration: underline;
            color: #1a1a1a;
          }
        }
      }
    }

    .btn-h {
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: flex-end;

      .link {
        color: #1A1A1A;
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
        text-decoration: none;
        display: flex;
        align-items: center;
        white-space: nowrap;

        &:hover {
          text-decoration: underline;
        }

        em {
          display: inline-block;
          padding: 0 10px;

          &:before {
            color: #1A1A1A;
          }
        }
      }

      .button {
        font-size: 12px;
        line-height: 23px;
        min-width: 96px;
        padding: 4px 12px;
        min-height: 28px;

        em {
          display: inline-block;
          padding: 0 5px;
          font-size: 9px;

          &:before {
            color: var(--white-color);
          }
        }
      }
    }
  }

  @media (max-width: 992px) {
    .items {
      flex-direction: column;
      gap: 20px;
      align-items: center;

      .item {
        text-align: left !important;

        &:first-of-type {
          display: none;
        }

        &:last-of-type {
          justify-content: center;
        }
      }

      .logo-h {
        display: none;
      }
    }
  }
}

.color-red {
  color: #FF001F;
}


.rtl-page {


  ol, ul {
    padding-left: 0;
    padding-right: 2rem;
  }


  .payment .items {
    justify-content: flex-start;
  }

  .payment .items .img-h picture {
    display: flex;
    justify-content: flex-end;
  }


  .icon-arrSliderR1:before {
    content: "\e922";
  }

  .holder__faq:before {
    left: auto;
    right: 0;
    transform: scaleX(-1);
  }


  @media only screen and (max-width: 1200px) {

  }


  @media only screen and (max-width: 900px) {
    .accordion__parent__faq .link-on:after {
      left: auto;
      right: 0;
    }
  }

  @media only screen and (max-width: 768px) {

  }

  @media only screen and (max-width: 480px) {
    .accordion__parent__faq .link-on:before {
      right: auto;
      left: 4px;
    }
    .accordion .accordion-item .accordion-header .accordion-button {
      padding: 0.5rem 1.5rem 0.5rem 5rem;
    }
  }
}